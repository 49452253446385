import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Privacy Policy notice`}</strong></p>
    <p>{`Data protection is very important to us and we want to be transparent and clear of how we process your personal information.`}</p>
    <p><strong parentName="p">{`Preamble`}</strong><br parentName="p"></br>{`
`}{`The purpose of this privacy policy is to describe what personal data is collected, the methods and purposes of processing, as well as the rights and security measures taken to protect them. These terms will apply, regardless of how and trough which device you accessed our website.`}</p>
    <p><strong parentName="p">{`What data we collect`}</strong><br parentName="p"></br>{`
`}{`CLAPESQUE collects personal data, which refers to any information relating to you and that identifies you, directly or indirectly.This may be your identity data (such as contact data, your name, surname, language and country from which you interact with us), your areas of interest, your size or style preferences, events you participate in, the pages you visit, cv data, technical data (as connection, geolocation and browsing data) and commercial information.`}</p>
    <p><strong parentName="p">{`Why we use your data and what for`}</strong><br parentName="p"></br>{`
`}{`we will use your data, among other purposes, to manage personalized content for you or to send you commercial communications, to analyze the ergonomy of our website through cookies or other technologies, improve our services for you.`}</p>
    <p><strong parentName="p">{`Who we share your data with`}</strong><br parentName="p"></br>{`
`}{`We will treat all your personal information as confidential. We will never share or sell your data outside clapesque for marketing purposes. Data that we forward to third party companies are only for limited reasons below. We share your data on a limited basis with trusted suppliers that provide us with services we offer to you. These are suppliers who provide us with technological services as the internet platform, financial institutions, advertising and marketing partners, providers of customer service or government bodies and law enforcement agencies or the ones that help us to prevent fraud. We exclusively disclose data to them that is strictly necessary for them to support us and please them to use your data only for the listed purposes.`}</p>
    <p><strong parentName="p">{`How we store your data`}</strong><br parentName="p"></br>{`
`}{`CLAPESQUE keeps your data for the time necessary to achieve the purpose we collected it for. This may be to satisfy your needs or to meet legal obligations.`}</p>
    <p><strong parentName="p">{`Where we store your data`}</strong><br parentName="p"></br>{`
`}{`Your data is stored in the eea european economic area and may be transferred and processed outside of the eea, considering the respectif laws.`}</p>
    <p><strong parentName="p">{`Your rights`}</strong><br parentName="p"></br>{`
`}{`We want to make sure you are fully aware of all of your data protection rights under the european directive gdpr (general data protection regulation).`}<br parentName="p"></br>{`
`}{`You are entitled to the following:`}<br parentName="p"></br>{`
`}{`The right to access – you have the right to request information about your personal data. Contact us and we will provide you with it via email.`}<br parentName="p"></br>{`
`}{`The right to rectify – you have the right to request rectification of your data if you believe it is inaccurate or incomplete.`}<br parentName="p"></br>{`
`}{`The right to delete – you have the right to erase your personal data, under certain conditions.`}<br parentName="p"></br>{`
`}{`If the data is no longer needed for the purpose for which it was collected (for example a placed order that has not been completely shipped, an ongoing customer service matter, an unsettled debt with CLAPESQUE, misuse of our service, bookkeeping rules following your purchase, etc.).`}<br parentName="p"></br>{`
`}{`The right to data portability – you have the right to request that CLAPESQUE transfers the data that we have collected to another organization, or directly to you, under certain conditions.`}<br parentName="p"></br>{`
`}{`The right to restrict processing – you have the right to request that CLAPESQUE restrict the processing of your personal data, under certain conditions.`}<br parentName="p"></br>{`
`}{`The right to object to processing – you have the right to object to CLAPESQUE processing of your personal data, under certain conditions.`}</p>
    <p><strong parentName="p">{`Children`}</strong><br parentName="p"></br>{`
`}{`Our online services are not designed for children. We do not knowingly collect personal data from children under 13.`}</p>
    <p><strong parentName="p">{`Cookies`}</strong><br parentName="p"></br>{`
`}{`Cookies are text files placed on your computer or mobile device to collect visitor behavior information. When you visit our website, we may collect information from you automatically through cookies or similar technologies to facilitate browsing, to make it more user-friendly and to save your preferences.`}</p>
    <p><strong parentName="p">{`They do not damage your device`}</strong><br parentName="p"></br>{`
`}{`We assume that you agree to the use of cookies when you use our services.`}<br parentName="p"></br>{`
`}{`You can easily erase cookies from your computer or mobile device using your browser. Keep in mind that if you disable cookies, you will not be able to use all our features.`}</p>
    <p><strong parentName="p">{`Google analytics`}</strong><br parentName="p"></br>{`
`}{`We use google analytics advertising features. If you want to read more about it or to opt out: `}<a parentName="p" {...{
        "href": "https://tools.google.com/dlpage/gaoptout/"
      }}>{`https://tools.google.com/dlpage/gaoptout/`}</a></p>
    <p>{`Changes to our privacy policy`}{`*`}{`*`}<br parentName="p"></br>{`
`}{`Our business is constantly evolving and so may need to update our privacy policies. You will always find the latest version of the privacy notice on our website.`}</p>
    <p><strong parentName="p">{`How to contact us`}</strong><br parentName="p"></br>{`
`}{`The Data Protection Officer may be contacted at the following address: hello@clapesque.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      